@import url("@/assets/font/font.css");

html,
body {
  margin: 0;
  padding: 0;
  font-family: PUHUI55;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

div {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 7px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 7px;
}

div::-webkit-scrollbar {
  width: 8px;
}

div::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 7px;
}

div::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 在小屏幕上修改样式 */
@media (max-width: 768px) {
  html,
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}
