@import url("./iconfont/iconfont.css");

/* 阿里妈妈数黑 */
@font-face {
  font-family: "SHUHEI";
  src: url("./alibaba_shuhei/AlimamaShuHeiTi-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

/* 普惠 2.0 55 Regular */
@font-face {
  font-family: "PUHUI55";
  src: url("./alibaba_puhui/Alibaba_PuHuiTi_2.0_55_Regular_55_Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

