@font-face {
  font-family: "iconfont"; /* Project id 4508276 */
  src: url('iconfont.woff2?t=1725538961109') format('woff2'),
       url('iconfont.woff?t=1725538961109') format('woff'),
       url('iconfont.ttf?t=1725538961109') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shanchu:before {
  content: "\e615";
}

.icon-earth:before {
  content: "\e8f9";
}

.icon-wendang:before {
  content: "\e60c";
}

.icon-wendang1:before {
  content: "\e60d";
}

.icon-wendang2:before {
  content: "\e623";
}

.icon-shiyongwendang:before {
  content: "\eb66";
}

.icon-diqiu:before {
  content: "\e719";
}

.icon-kefu2:before {
  content: "\e612";
}

.icon-kefu3:before {
  content: "\e738";
}

.icon-kefu5:before {
  content: "\e685";
}

.icon-dianzan:before {
  content: "\e64e";
}

.icon-API2:before {
  content: "\e650";
}

.icon-tishi:before {
  content: "\e61f";
}

.icon-tishi1:before {
  content: "\e66c";
}

.icon-chenggong:before {
  content: "\e631";
}

.icon-chenggong1:before {
  content: "\e608";
}

.icon-chenggong2:before {
  content: "\e644";
}

.icon-cuowu:before {
  content: "\e616";
}

.icon-tishi2:before {
  content: "\e61c";
}

.icon-tishi3:before {
  content: "\e636";
}

.icon-cuowu1:before {
  content: "\e65b";
}

.icon-cuowu2:before {
  content: "\e613";
}

.icon-chenggong3:before {
  content: "\e8c3";
}

.icon-cuowu3:before {
  content: "\e7fa";
}

.icon-tishi4:before {
  content: "\e60b";
}

.icon-cuowu4:before {
  content: "\e60a";
}

.icon-copy:before {
  content: "\e706";
}

.icon-copy1:before {
  content: "\e61a";
}

.icon-copy2:before {
  content: "\e617";
}

.icon-copy-l:before {
  content: "\e61b";
}

.icon-dengluzhuceguanbi:before {
  content: "\e630";
}

.icon-guanbi:before {
  content: "\e611";
}

.icon-guanbi1:before {
  content: "\e639";
}

.icon-guanbi2:before {
  content: "\e651";
}

.icon-sousuo:before {
  content: "\e641";
}

.icon-sousuo1:before {
  content: "\e619";
}

.icon-sousuo2:before {
  content: "\e604";
}

.icon-sousuo3:before {
  content: "\e63f";
}

.icon-baidufanyi:before {
  content: "\e723";
}

.icon-baidufanyi1:before {
  content: "\e618";
}

.icon-youdao1:before {
  content: "\e605";
}

.icon-baidu_fanyi:before {
  content: "\e606";
}

.icon-DeepL:before {
  content: "\e622";
}

.icon-googlefanyi:before {
  content: "\e64d";
}

.icon-shangla-copy:before {
  content: "\e81b";
}

.icon-qiehuan:before {
  content: "\e62d";
}

.icon-qiehuan-:before {
  content: "\e640";
}

.icon-qiehuan1:before {
  content: "\e741";
}

.icon-qiehuan2:before {
  content: "\e607";
}

.icon-xiala:before {
  content: "\e621";
}

.icon-haiwai-xian:before {
  content: "\e698";
}

.icon-zhinengfanyidingzhi:before {
  content: "\e74a";
}

.icon-fanyi-xuanzhong:before {
  content: "\e675";
}

.icon-fanyi1:before {
  content: "\e818";
}

.icon-zhizuofanyi:before {
  content: "\e729";
}

.icon-zidongzengliangfanyi:before {
  content: "\e6b1";
}

.icon-piliangfanyiyingwenbiaoti:before {
  content: "\e62f";
}

.icon-zhuye-fanyi:before {
  content: "\e602";
}

.icon-bianzu:before {
  content: "\e66e";
}

.icon-fanyi3:before {
  content: "\e643";
}

.icon-haiwai-xian-copy:before {
  content: "\e819";
}

.icon-gugefanyi:before {
  content: "\e64f";
}

.icon-menu_icon_jhfy-01:before {
  content: "\e624";
}

.icon-jiaohushianniu_fanyi:before {
  content: "\e68a";
}

.icon-zhongyingfanyi:before {
  content: "\e620";
}

.icon-qy-xiaogongju-sousuokuangfanyi:before {
  content: "\e62c";
}

.icon-daohang-fanyi-anxia:before {
  content: "\e657";
}

.icon-zhuye-fanyi-copy:before {
  content: "\e81a";
}

.icon-view_pyramid_customertransformation:before {
  content: "\e62b";
}

.icon-zhongyiying:before {
  content: "\e62e";
}

.icon-fanyi:before {
  content: "\e7e1";
}

.icon-a-MATE_huaban1fuben37:before {
  content: "\e60f";
}

.icon-xgaozhiliang:before {
  content: "\e629";
}

.icon-duifuwu-:before {
  content: "\e627";
}

.icon-API:before {
  content: "\e68c";
}

.icon-juhe:before {
  content: "\e659";
}

.icon-dui:before {
  content: "\e647";
}

.icon-dui1:before {
  content: "\e64b";
}

.icon-API1:before {
  content: "\e603";
}

.icon-jiageyouhui:before {
  content: "\e718";
}

.icon-1dui1:before {
  content: "\e628";
}

.icon-juhe1:before {
  content: "\e734";
}

.icon-1dui11:before {
  content: "\e6d5";
}

.icon-huaban72:before {
  content: "\e600";
}

.icon-gaozhiliang:before {
  content: "\e64c";
}

.icon-gaozhiliang1:before {
  content: "\e648";
}

.icon-method-draw-image9:before {
  content: "\e601";
}

.icon-APIwangguan:before {
  content: "\e66d";
}

.icon-a-1dui1fuwu:before {
  content: "\e64a";
}

.icon-a-jiageyouhui_huaban1:before {
  content: "\e686";
}

.icon-APIguanli:before {
  content: "\e635";
}

.icon-juhe2:before {
  content: "\e6a2";
}

.icon-juhe3:before {
  content: "\e67d";
}

.icon-gaozhiliang2:before {
  content: "\e65e";
}

.icon-a-1dui1:before {
  content: "\e758";
}

.icon-jiagebaohu:before {
  content: "\e62a";
}

.icon-a-1dui1fuwu1:before {
  content: "\e609";
}

.icon-APIwangguan-copy:before {
  content: "\e8c4";
}

